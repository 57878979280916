import http from "./http";

// 获取首页信息
export function getIndexInfo() {
  return http({
    url: "/addons/litestore/api.index/index",
    method: "GET",
    headers: {},
  });
}

// 获取分类
export function getCategory() {
  return http({
    url: "/addons/litestore/api.category/Showlist",
    method: "GET",
    headers: {},
  });
}

// 历史浏览记录
export function historyList() {
  return http({
    url: "/addons/litestore/api.goods/history_list",
    method: "GET",
    headers: {},
  });
}

// 图片上传
export function uploadFile(params) {
  return http({
    url: "/api/common/upload",
    method: "POST",
    headers: {},
    data: params,
  });
}

// 评论-添加
export function addReview(params) {
  return http({
    url: "/api/order_appraise/add",
    method: "POST",
    headers: {},
    data: params,
  });
}

//
export function articleList() {
  return http({
    url: "/api/index/article",
    method: "POST",
    headers: {},
  });
}

//
export function articleDetail(params) {
  return http({
    url: "/api/index/article_detail",
    method: "POST",
    headers: {},
    params,
  });
}

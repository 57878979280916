import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Input } from "antd-mobile";
import { articleList } from "@/api/index";
import styles from "./index.module.scss";

const Footer = (props) => {
  const { history } = props;
  const [list, setList] = useState([]);
  const getList = async () => {
    const res = await articleList();
    if (res.code === 1) {
      setList(res.data);
    }
  };

  useEffect(() => {
    if (
      history.location.pathname === "/report" ||
      history.location.pathname === "/flow"
    ) {
      return;
    }
    getList();
  }, []);

  const goDetail = (id) => {
    history.push(`/article/${id}`);
  };

  if (
    history.location.pathname === "/report" ||
    history.location.pathname === "/flow"
  ) {
    return <></>;
  }

  return (
    <>
      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.footerInner}>
            <div className={styles.block}>
              <div className={styles.blockTitle}>Info</div>
              <div className={styles.linklist}>
                {list.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        goDetail(item.id);
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>
              <div className={styles.blockTitle}>Newsletter</div>
              <div className={styles.footerContent}>
                Subscribe to receive updates, access to exclusive deals, and
                more.
              </div>

              <div className={styles.blockTitle}>Contact with Us</div>
              <Input
                className={styles.emailInput}
                placeholder="Enter your email address"
                style={{
                  "--placeholder-color": "#939393",
                }}
              />
              <div className={styles.subscribe}>Subscribe</div>
              <div className={styles.footerContent}>
                Have questions about shopping, need to check on your order or
                want to know more about Herfes We’re always here to help. Just
                reach out by email. Our service team will reply and solve for
                you within 24 hours.
              </div>
              <div className={styles.email}>
                Email us: <strong>Herfes@Herfes.com</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Footer);

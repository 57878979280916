// @ts-nocheck
import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { get } from "lodash";
import Loadable from "react-loadable";
import { ConfigProvider } from "antd-mobile";
import { Cookie } from "@/utils/cookie";
import Footer from "@/components/Common/Footer";
import IndexHeader from "@/components/Index/Header";

import zhCN from "antd-mobile/es/locales/zh-CN";

import reducer from "@/reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const loadableProps = {
  timeout: 60 * 1000,
};

const Loading = () => null;

const Index = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "index" */ "@/pages/Index/Index"),
  loading: Loading,
});

const Collections = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Collections" */ "@/pages/Collections/Index"),
  loading: Loading,
});

const ProductDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "ProductDetail" */ "@/pages/ProductDetail/Index"
    ),
  loading: Loading,
});

const WriteReview = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "WriteReview" */ "@/pages/WriteReview/Index"),
  loading: Loading,
});

const Cart = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Cart" */ "@/pages/Cart/Index"),
  loading: Loading,
});

const Checkout = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Checkout" */ "@/pages/Checkout/Index"),
  loading: Loading,
});

const Shipping = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "Shipping" */ "@/pages/Checkout/Shipping/Index"
    ),
  loading: Loading,
});

const Account = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Account" */ "@/pages/Account/Index"),
  loading: Loading,
});

const AccountAddress = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "AccountAddress" */ "@/pages/Account/AccountAddress/Index"
    ),
  loading: Loading,
});

const BloggerGoods = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "BloggerGoods" */ "@/pages/BloggerGoods/Index"),
  loading: Loading,
});

const ApplyGoods = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "ApplyGoods" */ "@/pages/ApplyGoods/Index"),
  loading: Loading,
});

const Login = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Login" */ "@/pages/Login/Index"),
  loading: Loading,
});

const Register = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Register" */ "@/pages/Register/Index"),
  loading: Loading,
});

const OrderDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "OrderDetail" */ "@/pages/OrderDetail/Index"),
  loading: Loading,
});

const Withdraw = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Withdraw" */ "@/pages/Withdraw/Index"),
  loading: Loading,
});

const ApplePay = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "ApplePay" */ "@/pages/ApplePayDemo/Index"),
  loading: Loading,
});

const ArticleDetail = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "ArticleDetail" */ "@/pages/ArticleDetail/Index"
    ),
  loading: Loading,
});

const Report = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Report" */ "@/pages/Report/Index"),
  loading: Loading,
});

const Flow = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Flow" */ "@/pages/Flow/Index"),
  loading: Loading,
});

const NotFound = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "notfount" */ "@/pages/NotFound/NotFound"),
  loading: Loading,
});

const init = () => {
  const token = Cookie.get("fatToken");
  const uuid = Cookie.get("uuid");
  if (!token && !uuid) {
    const uid = Math.round(Math.random() * 100000000);
    Cookie.set("uuid", uid);
  }
};
init();

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <Router>
          <IndexHeader />
          <Switch>
            <Route exact path="/" component={Index} />
            <Route exact path="/report" component={Report} />
            <Route exact path="/flow" component={Flow} />
            <Route
              exact
              path="/collections/:id/:flag?/:bloggerId?"
              component={Collections}
            />
            <Route
              exact
              path="/productDetail/:id/:bloggerId?"
              component={ProductDetail}
            />
            <Route
              exact
              path="/productWriteReview/:id"
              component={WriteReview}
            />
            <Route exact path="/cart" component={Cart} />
            <Route
              exact
              path="/checkout/:source/:id?/:num?/:sku?/:bloggerId?"
              component={Checkout}
            />
            <Route
              exact
              path="/shipping/:source/:id?/:num?/:sku?/:bloggerId?"
              component={Shipping}
            />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/address" component={AccountAddress} />
            <Route exact path="/bloggerGoods" component={BloggerGoods} />
            <Route exact path="/applyGoods" component={ApplyGoods} />
            <Route exact path="/withdraw" component={Withdraw} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/orderDetail/:id" component={OrderDetail} />
            <Route exact path="/applepay" component={ApplePay} />
            <Route exact path="/article/:id" component={ArticleDetail} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;

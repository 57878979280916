import http from "./http";

// 购物车列表
export function getCartLists(params = {}) {
  return http({
    url: "/addons/litestore/api.cart/getlists",
    method: "POST",
    headers: {},
    data: params,
  });
}

// 添加购物车（增加购物车商品数量）
export function addCart(params) {
  return http({
    url: "/addons/litestore/api.cart/add",
    method: "POST",
    headers: {},
    data: params,
  });
}

// 减少购物车中某商品数量
export function subCart(params) {
  return http({
    url: "/addons/litestore/api.cart/sub",
    method: "POST",
    headers: {},
    data: params,
  });
}

// 删除购物车商品
export function deleteCart(params) {
  return http({
    url: "/addons/litestore/api.cart/delete",
    method: "POST",
    headers: {},
    data: params,
  });
}

// index
export const SET_INDEX_STATE = "SET_INDEX_STATE";

// collections
export const SET_COLLECTIONS_STATE = "SET_COLLECTIONS_STATE";

// PRODUCTDETAIL
export const SET_PRODUCTDETAIL_STATE = "SET_PRODUCTDETAIL_STATE";

// SET_CART_STATE
export const SET_CART_STATE = "SET_CART_STATE";

// checkout
export const SET_CHECKOUT_STATE = "SET_CHECKOUT_STATE";

import Cookies from "js-cookie";

export const Domain = document.domain.split(".").slice(-2).join("."); // 一级域名，eg："wywk.cn"

export const Cookie = {
  get(name: string) {
    return Cookies.get(name) || "";
  },
  set(name: string, value: string, cDomain?: string, expires?: number) {
    // 默认7天后过期
    Cookies.set(name, value, {
      domain: cDomain || Domain,
      expires: expires || 7,
    });
  },
  remove(name: string, cDomain?: string) {
    Cookies.remove(name, { domain: cDomain || Domain });
  },
};

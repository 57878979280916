export default () => {
  const hostConfig = {
    test: ["new.yang1004.top"],
    uat: ["new.yang1004.top"],
    prod: ["new.yang1004.top"],
  };
  const host = document.domain.toLowerCase();
  let env = "dev";

  Object.keys(hostConfig).some((key) => {
    if (hostConfig[key].includes(host)) {
      env = key;
      return true;
    }
    return false;
  });

  return env;
};

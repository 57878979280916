import React, { useEffect, useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { throttle } from "lodash";
import { connect } from "react-redux";
import { Input, Button, Toast } from "antd-mobile";
import { getCategoryAction } from "@/actions/index";
import { getCartListsAction } from "@/actions/cart";
import { Popup, Collapse, TextArea } from "antd-mobile";
import { CloseOutline, MinusOutline, AddOutline } from "antd-mobile-icons";
import CartItem from "@/components/Cart/CartItem";
import logoImg from "@/assets/image/logo.png";
import menuImg from "@/assets/image/index/menu.png";
import searchImg from "@/assets/image/index/search.png";
import cartImg from "@/assets/image/index/cart.png";
import menuBImg from "@/assets/image/index/menu_b.png";
import searchBImg from "@/assets/image/index/search_b.png";
import cartBImg from "@/assets/image/index/cart_b.png";
import closeImg from "@/assets/image/index/close.png";
import styles from "./index.module.scss";

const Index = (props) => {
  const { history, categorydata = [], order_pay_price, goods_list } = props;

  const [hasVerticalScrolled, setHasVerticalScrolled] = useState(false);
  const [leftVisible, setLeftVisible] = useState(false);
  const [rightVisible, setRightVisible] = useState(false);
  const [topVisible, setTopVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const onScrollHandle = useCallback((event) => {
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0);

    setHasVerticalScrolled(scrollTop > 30);
  }, []);

  const onScrollThrottle = useCallback(
    throttle((event) => {
      onScrollHandle(event);
    }, 200),
    []
  );

  const getInfo = () => {
    props.getCategoryAction();
  };

  useEffect(() => {
    if (rightVisible) {
      props.getCartListsAction();
    }
  }, [rightVisible]);

  useEffect(() => {
    if (
      history.location.pathname === "/report" ||
      history.location.pathname === "/flow"
    ) {
      return;
    }
    props.getCartListsAction();
    getInfo();
  }, []);

  useEffect(() => {
    history.listen((location) => {
      window.scrollTo(0, 0);
      if (location.pathname === "/") {
        window.addEventListener("scroll", onScrollThrottle);
      } else {
        setHasVerticalScrolled(true);
        window.removeEventListener("scroll", onScrollThrottle);
      }
    });

    const { pathname } = history.location;
    if (pathname === "/") {
      window.addEventListener("scroll", onScrollThrottle);
    } else {
      setHasVerticalScrolled(true);
      window.removeEventListener("scroll", onScrollThrottle);
    }
    return () => {
      window.removeEventListener("scroll", onScrollThrottle);
    };
  }, []);

  if (
    history.location.pathname === "/report" ||
    history.location.pathname === "/flow"
  ) {
    return <></>;
  }

  return (
    <>
      <div
        className={`${styles.header} ${
          hasVerticalScrolled && styles.headerScrolled
        }`}
      >
        <div className={styles.headerLeft}>
          <img
            src={hasVerticalScrolled ? menuBImg : menuImg}
            alt="menuImg"
            onClick={() => {
              setLeftVisible(true);
            }}
          />
        </div>
        <div className={styles.headerLogoBox}>
          <img
            className={styles.headerLogo}
            onClick={() => {
              history.push(`/`);
            }}
            src={logoImg}
            alt="logo"
          />
        </div>
        <div className={styles.headerRight}>
          <img
            src={hasVerticalScrolled ? searchBImg : searchImg}
            alt="searchImg"
            onClick={() => {
              // history.push(`/cart`);
              setTopVisible(true);
            }}
          />

          <div className={styles.cartBox}>
            <img
              src={hasVerticalScrolled ? cartBImg : cartImg}
              alt="cartImg"
              onClick={() => {
                // history.push(`/cart`);
                setRightVisible(true);
              }}
            />
            {goods_list.length > 0 ? <div className={styles.cartRate} /> : null}
          </div>
        </div>
      </div>
      <Popup
        visible={topVisible}
        onMaskClick={() => {
          setTopVisible(false);
        }}
        afterClose={() => {
          setSearchValue("");
        }}
        position="top"
        // bodyStyle={{ width: "80vw" }}
      >
        <div className={styles.topPop}>
          <div className={styles.searchBox}>
            <img
              src={searchBImg}
              alt=""
              onClick={() => {
                setTopVisible(false);
              }}
            />
            <Input
              className={styles.searchInput}
              placeholder="Search..."
              style={{
                "--placeholder-color": "#939393",
              }}
              onChange={(e) => {
                setSearchValue(e);
              }}
              value={searchValue}
            />
            <img src={closeImg} alt="" />
          </div>
          <Button
            type="submit"
            color="primary"
            fill="solid"
            className={styles.continueButton}
            onClick={() => {
              if (!searchValue) {
                Toast.show({
                  content: "请输入搜索内容",
                });
              } else {
                setTopVisible(false);
                history.push(`/collections/search/${searchValue}`);
              }
            }}
          >
            Search
          </Button>
        </div>
      </Popup>
      <Popup
        visible={rightVisible}
        onMaskClick={() => {
          setRightVisible(false);
        }}
        position="right"
        bodyStyle={{ width: "80vw" }}
      >
        <div className={styles.rightPop}>
          <div className={styles.rightPopHeader}>
            <span className={styles.titleCart}>Cart</span>
            <div
              className={styles.headerClose}
              onClick={() => {
                setRightVisible(false);
              }}
            >
              <CloseOutline color="#5c5c5c" fontSize={20} />
            </div>
          </div>
          <div className={styles.rightBottom}>
            {goods_list.length > 0 ? (
              <>
                <div className={styles.cartList}>
                  {goods_list.map((item) => {
                    return (
                      <CartItem
                        data={item}
                        key={`${item.goods_id}_${item.goods_sku_id}`}
                      />
                    );
                  })}
                </div>
                <div className={styles.footerBox}>
                  <div className={styles.noteContainer}>
                    <span className={styles.noteTitle}>Add Order Note</span>
                    <TextArea
                      className={styles.noteTextArea}
                      placeholder="How can we help you?"
                      style={{
                        "--placeholder-color": "#434343",
                        "--color": "#434343",
                        "--font-size": "15px",
                      }}
                    />
                  </div>
                  <div className={styles.recap}>
                    <div className={styles.subdued}>
                      Shipping & taxes calculated at checkout
                    </div>
                    <div
                      className={styles.checkout}
                      onClick={() => {
                        setRightVisible(false);
                        history.push(`/checkout/cart`);
                      }}
                    >
                      Checkout <span /> ${order_pay_price}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.rightEmpty}>Your cart is empty</div>
            )}
          </div>
        </div>
      </Popup>
      <Popup
        visible={leftVisible}
        onMaskClick={() => {
          setLeftVisible(false);
        }}
        position="left"
        bodyStyle={{ minWidth: "80vw" }}
      >
        <div className={styles.leftPop}>
          <div className={styles.leftPopHeader}>
            <div
              className={styles.headerClose}
              onClick={() => {
                setLeftVisible(false);
              }}
            >
              <CloseOutline color="#5c5c5c" fontSize={20} />
            </div>
          </div>
          <div className={styles.leftBottomList}>
            <div
              className={styles.collapsible}
              onClick={() => {
                setLeftVisible(false);
                history.push(`/`);
              }}
            >
              Home
            </div>
            <Collapse
              accordion
              arrow={(active) =>
                active ? (
                  <MinusOutline color="#323232" fontSize={14} />
                ) : (
                  <AddOutline color="#323232" fontSize={14} />
                )
              }
            >
              {categorydata.map((item) => {
                return (
                  <Collapse.Panel key={item.id} title={item.name}>
                    <div className={styles.panelContent}>
                      {item.childlist.map((childItem) => {
                        return (
                          <div
                            key={childItem.id}
                            className={styles.panelContentItem}
                            onClick={() => {
                              setLeftVisible(false);
                              history.push(`/collections/${childItem.id}`);
                            }}
                          >
                            {childItem.name}
                          </div>
                        );
                      })}
                    </div>
                  </Collapse.Panel>
                );
              })}
            </Collapse>
            <div
              className={styles.collapsible}
              onClick={() => {
                setLeftVisible(false);
                history.push(`/account`);
              }}
            >
              ACCOUNT
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

const mapStateToProps = (state) => {
  const { categorydata } = state.pageIndex;
  const { order_pay_price, goods_list } = state.cart;

  return { categorydata, order_pay_price, goods_list };
};

export default connect(mapStateToProps, {
  getCategoryAction,
  getCartListsAction,
})(withRouter(Index));

import axios from "axios";
import { Toast } from "antd-mobile";
import { getEnv } from "@/utils";
import { Cookie } from "@/utils/cookie";

const apiHost = {
  dev: "https://shop.herfesus.com",
  test: "https://shop.herfesus.com",
  uat: "https://shop.herfesus.com",
  prod: "https://shop.herfesus.com",
};

// const apiHost = {
//   dev: "https://new.yang1004.top/api.php",
//   test: "https://new.yang1004.top/api.php",
//   uat: "https://new.yang1004.top/api.php",
//   prod: "https://www.herfesus.com/api.php",
// };

const url =
  process.env.NODE_ENV === "development" ? apiHost.dev : apiHost[getEnv()];

const http = axios.create({
  baseURL: url,
  timeout: 30 * 1000,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    token: Cookie.get("fatToken"),
    uuid: Cookie.get("uuid"),
  },
});

// Add a request interceptor
http.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      const data = new FormData();

      for (const key in config.data || {}) {
        data.append(key, config.data[key]);
      }
      config.data = data;
    }
    if (config.headers.urlPrefix) {
      return {
        ...config,
        baseURL: `${url}/${config.headers.urlPrefix}/`,
        headers: {
          ...config.headers,
          token: Cookie.get("fatToken"),
          uuid: Cookie.get("uuid"),
        },
      };
    }
    return {
      ...config,
      baseURL: `${url}/api.php/`,
      headers: {
        ...config.headers,
        token: Cookie.get("fatToken"),
        uuid: Cookie.get("uuid"),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  (response) => {
    if (response.data.code !== 1) {
      const msg = response.data.msg;
      if (typeof msg === "string") {
        Toast.show({
          content: response.data.msg || "服务异常，请稍后重试",
        });
      }
      return Promise.reject(response.data);
    }
    return response.data;
  },
  (error) => {
    Toast.show({
      content: "网络开小差了，请稍后重试",
    });
    return Promise.reject(error);
  }
);

export default http;

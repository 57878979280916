import { getCartLists, addCart, subCart, deleteCart } from "@/api/cart";
import * as actionType from "./actionType";

export const updateState = (obj) => {
  return {
    type: actionType.SET_CART_STATE,
    payload: {
      ...obj,
    },
  };
};

export const getCartListsAction = () => {
  return async (dispatch) => {
    const res = await getCartLists();

    if (res.code === 1) {
      dispatch(updateState({ ...res.data }));
    }
  };
};

export const addCartAction = (obj) => {
  return async () => {
    return await addCart(obj);
  };
};

export const subCartAction = (obj) => {
  return async () => {
    return await subCart(obj);
  };
};

export const deleteCartAction = (obj) => {
  return async () => {
    return await deleteCart(obj);
  };
};

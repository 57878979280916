/* eslint-disable import/no-anonymous-default-export */
import * as actionType from "@/actions/actionType";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_CHECKOUT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

/* eslint-disable import/no-anonymous-default-export */
import * as actionType from "@/actions/actionType";

const initialState = {
  list: [],
  page: 1,
  hasMore: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_COLLECTIONS_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

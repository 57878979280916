import React from "react";
import { Stepper } from "antd-mobile";
import { connect } from "react-redux";
import {
  addCartAction,
  getCartListsAction,
  subCartAction,
  deleteCartAction,
} from "@/actions/cart";
import styles from "./index.module.scss";

const CartItem = (props) => {
  const { data = {} } = props;

  const changeCart = async (e) => {
    const totalNum = data.total_num;
    if (e > totalNum) {
      await props.addCartAction({
        goods_id: data.goods_id,
        goods_num: 1,
        goods_sku_id: data.goods_sku_id,
      });
    } else {
      await props.subCartAction({
        goods_id: data.goods_id,
        goods_sku_id: data.goods_sku_id,
      });
    }
    props.getCartListsAction();
  };

  const removeCart = async () => {
    await props.deleteCartAction({
      goods_id: data.goods_id,
      goods_sku_id: data.goods_sku_id,
    });
    props.getCartListsAction();
  };

  return (
    <>
      <div className={styles.content}>
        <div
          className={styles.cartItemImage}
          style={{
            backgroundImage: `url(${data.image})`,
          }}
        />
        <div className={styles.cartInfo}>
          <div className={styles.title}>{data.goods_name}</div>
          <div className={styles.variant}>{data.goods_sku?.goods_attr}</div>
          <div className={styles.priceList}>
            <span className={styles.price}>${data.goods_sku?.goods_price}</span>
            <span className={styles.oldPrice}>
              ${data.goods_sku?.line_price}
            </span>
          </div>
          <div className={styles.boxStepperDiv}>
            <div className={styles.boxStepper}>
              <Stepper
                style={{
                  "--input-font-size": "12px",
                  "--input-font-color": "#434343",
                  "--button-width": "26px",
                  "--button-text-color": "#434343",
                  "--input-width": "24px",
                  "--height": "36.75px",
                }}
                inputReadOnly
                value={data.total_num}
                digits={0}
                min={1}
                max={999}
                onChange={(e) => {
                  changeCart(e);
                }}
              />
            </div>
            <div className={styles.remove} onClick={removeCart}>
              Remove
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  addCartAction,
  getCartListsAction,
  subCartAction,
  deleteCartAction,
})(CartItem);

import { getIndexInfo, getCategory, historyList } from "@/api/index";
import * as actionType from "./actionType";

export const updateState = (obj) => {
  return {
    type: actionType.SET_INDEX_STATE,
    payload: {
      ...obj,
    },
  };
};

export const getHistoreListAction = () => {
  return async (dispatch) => {
    const res = await historyList();

    if (res.code === 1) {
      dispatch(updateState({ historyList: res.data }));
    }
  };
};

export const getIndexInfoAction = () => {
  return async (dispatch) => {
    const res = await getIndexInfo();

    if (res.code === 1) {
      dispatch(updateState({ ...res.data }));
    }
  };
};

export const getCategoryAction = () => {
  return async (dispatch) => {
    const res = await getCategory();

    if (res.code === 1) {
      dispatch(updateState({ ...res.data }));
    }
  };
};

import { combineReducers } from "redux";

import pageIndex from "./pageIndex";
import collections from "./collections";
import productDetail from "./productDetail";
import cart from "./cart";
import checkout from "./checkout";

export default combineReducers({
  pageIndex,
  collections,
  productDetail,
  cart,
  checkout,
});
